import { useState, Fragment, Suspense, lazy } from "react";

//Custom Components
import { CardTable, Tr } from "../../../components/Table/CardTable";
import { EditButton } from "../../../components/Buttons";
import { BannerAlert } from "../../../components/Alerts";
import { RowLoader } from "../../../components/Loaders";
//lazy load the component
const EditInstitutionModal = lazy(() => import("./EditInstitutionModal"));

type InstitutionalAffiliationsProps = {
  data: any;
  pending: boolean;
  incomplete: boolean;
};

export default function InstitutionalAffiliations({
  data,
  pending,
  incomplete,
}: InstitutionalAffiliationsProps): JSX.Element {
  const [isEditing, setEditing] = useState(false);
  const [showSuccess] = useState(
    sessionStorage.getItem("updatedInstitutionInfo")
  );

  function showSuccessCallback() {
    sessionStorage.setItem("updatedInstitutionInfo", "true");
    window.location.reload();
  }
  return (
    <CardTable
      incomplete={incomplete}
      section_title="INSTITUTIONAL AFFILIATION"
      side_button={
        <EditButton
          aria-label="Edit Institutional Affiliations via edit institution form"
          disabled={!!pending}
          onClick={() => setEditing(true)}
        />
      }
      alert={
        !!showSuccess && (
          <BannerAlert
            variant="success"
            message="Successfully updated Institutional Affiliations"
            removeAlert={() =>
              sessionStorage.removeItem("updatedInstitutionInfo")
            }
          />
        )
      }
    >
      <Fragment>
        <Suspense fallback={<RowLoader />}>
          {!pending ? (
            <Fragment>
              <EditInstitutionModal
                institution_data={data}
                show={isEditing}
                onHide={() => setEditing(false)}
                successCallback={showSuccessCallback}
              />
              <Tr head="Occupation:" data={data.professional_rank} />
              <Tr head="Institution Name:" data={data.user_affiliation} />
              <Tr
                head="Country:                    "
                data={data.User_Institution_country}
              />
            </Fragment>
          ) : (
            <RowLoader />
          )}
        </Suspense>
      </Fragment>
    </CardTable>
  );
}
