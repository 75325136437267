//react builtins
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
//custom hooks
import useLogout from "../hooks/useLogout";
//custom components & styles
import SkipNav from "./skipNav/SkipNav";
import { ButtonLoader } from "./Loaders";
import { BannerAlert } from "./Alerts";
//react-bootstrap
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
//font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/img/logo.jpeg";
//context
import { UserDataContext } from "../layouts/userPortal/UserPortal";

export default function UserPortalHeader() {
  const {
    logout,
    error: logoutError,
    isPending: logoutIsPending,
  } = useLogout();

  const { data } = useContext(UserDataContext);

  const userAgent = navigator.userAgent;
  const isChrome = /Chrome/.test(userAgent);
  const isFirefox = /Firefox/.test(userAgent);
  const notSupported = !isChrome && !isFirefox;

  return (
    <Fragment>
      {/* Light Navbar */}
      <SkipNav id="main-profile-content" />
      <Navbar bg="light" expand="lg" aria-label="NYPL LOGO and Logout">
        <Container>
          <Navbar.Brand className="d-flex">
            <Image
              roundedCircle
              src={logo}
              alt="The New York Public Library logo"
              width="50"
              height="50"
              aria-hidden
            />
            <p
              className="my-auto ms-3 text-nypl-danger fw-bold text-wrap text-center"
              style={{ fontSize: "35px" }}
            >
              The New York Public Library
            </p>
          </Navbar.Brand>
          <Nav>
            {!logoutIsPending ? (
              <Button
                variant="outline-dark"
                className="my-auto ms-auto rounded-pill"
                onClick={() => logout()}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout
              </Button>
            ) : (
              <ButtonLoader text="logging out" />
            )}
          </Nav>
        </Container>
      </Navbar>
      {/* Dark Navbar */}
      <nav
        className="navbar navbar-expand-lg navbar-dark nav-tabs bg-dark"
        aria-label="navigate between pages"
      >
        <Container className="justify-content-center">
          {/* Nypl logo */}
          <div className="navbar-brand d-flex"></div>
          <Button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="navbar-toggler-icon"></div>
          </Button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {/* My profile */}
              <li className="nav-item">
                <Link className="nav-link" to="/user-portal">
                  My Profile
                </Link>
              </li>
              {/* Applications */}
              {data?.profile_complete && (
                <li className="nav-item">
                  <Link className="nav-link" to="/user-portal/my-applications">
                    My Applications
                  </Link>
                </li>
              )}
              {/* Fellowships */}
              {data?.profile_complete && (
                <li className="nav-item">
                  <Link className="nav-link" to="/user-portal/fellowships">
                    Fellowships
                  </Link>
                </li>
              )}
              {/* Issues and Concerns */}
              <li className="nav-item">
                <Link className="nav-link" to="/user-portal/report-issues">
                  Report Issues/Concerns
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </nav>
      {logoutError && (
        <div className="row justify-content-center m-1">
          <BannerAlert
            variant="danger"
            title="Error logging Out"
            message={logoutError}
          />
        </div>
      )}
      {notSupported && (
        <div className="row justify-content-center m-1">
          <BannerAlert
            dismissible={false}
            variant="warning"
            title="Warning: Unsupported Browser"
            message={
              <>
                Some features may not work properly. This website works best on{" "}
                <a
                  href="http://chrome.google.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Google Chrome
                </a>{" "}
                or{" "}
                <a
                  href="https://www.mozilla.org/en-US/firefox/new/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Mozilla Firefox
                </a>
                . Please consider switching for the best experience. check out
                other compatible browsers{" "}
                <a
                  href="https://alternativeto.net/category/browsers/chromium-based/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  here
                </a>
                .
              </>
            }
          />
        </div>
      )}
    </Fragment>
  );
}
